import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import EditCell from "examples/Tables/EditCell";
import { useQuery } from "lib/hooks";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDAvatar from "components/MDAvatar";
import DefaultCell from "examples/Tables/DefaultCell";
import { decodeSearchParams, formatMoney } from "lib/utils/helper";
import Icon from "@mui/material/Icon";
import dayjs from "dayjs";
import Pagination from "../../examples/Tables/DataTable/Pagination";
import PlainTextCell from "../../examples/Tables/PlainTextCell";
import RenderOption from "../merchant/merch-templates/[id]/RenderOption";
import { getKeyFromName } from "../../lib/utils/merchandise";
import PreviewModal from "./PreviewModal";
import Status from "./Status";

function TicketTempaltes() {
  const [searchParams] = useSearchParams();
  const [active, setActive] = useState(null);
  const searchDto = decodeSearchParams(searchParams);
  const { data, loading, fetchData } = useQuery({
    uri: "/delivery",
    params: { page: 1, limit: 20 },
    manual: true,
  });

  useEffect(() => {
    if (!loading) {
      fetchData("/delivery", { ...searchDto, limit: 20 });
    }
  }, [searchParams]);

  const { deliveries = [], totalCount } = data || {};

  return (
    <Card>
      <PreviewModal
        open={!!active}
        onClose={() => setActive(null)}
        active={active}
        refetch={() => fetchData("/delivery", { ...searchDto, limit: 20 })}
      />
      <DataTable
        loading={loading}
        entriesPerPage={false}
        paginationComp={<Pagination totalCount={totalCount} limit={20} />}
        table={{
          columns: [
            {
              Header: "Merch image",
              accessor: "productId.templateId.imageUrl",
              Cell: ({ value }) => {
                const [image] = value;
                return <MDAvatar size="xl" src={image} variant="rounded" alt="portal" />;
              },
            },
            {
              Header: "Name",
              accessor: "productId.name",
              Cell: ({ value, row }) => (
                <>
                  <DefaultCell value={value} />
                  <MDBox display="flex" flexWrap="wrap" gap={1} alignItems="center" mt={1}>
                    {Object.entries(row.original?.productId?.merchItemId?.attrs || {})?.map(
                      ([key, val]) => (
                        <RenderOption value={val} isColor={getKeyFromName(key) === "color"} />
                      )
                    )}
                  </MDBox>
                </>
              ),
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: ({ value }) => <Status status={value} />,
            },
            {
              Header: "Receiver",
              accessor: "receiverName",
              Cell: ({ value, row }) => (
                <PlainTextCell
                  value={`${value}, ${row.original.phoneNumber}, ${row.original.receiverEmail}`}
                />
              ),
            },
            {
              Header: "Delivery address",
              accessor: "city",
              Cell: ({ value, row }) => (
                <PlainTextCell
                  value={`${value}, ${row.original.district}, ${row.original.quarter}`}
                />
              ),
            },
            {
              Header: "Date",
              accessor: "createtAt",
              Cell: ({ value }) => <DefaultCell value={dayjs(value).format("YYYY-MM-DD HH:mm")} />,
            },

            {
              sorted: "none",
              accessor: "_id",
              Header: "Action",
              Cell: ({ row }) => (
                <MDButton
                  variant="gradient"
                  color="secondary"
                  iconOnly
                  onClick={() => {
                    setActive(row.original);
                  }}
                >
                  <Icon>visibility</Icon>
                </MDButton>
              ),
            },
          ],
          rows: deliveries || [],
        }}
      />
    </Card>
  );
}

export default TicketTempaltes;
