export const initialEvent = {
  cover_image_v: "https://cdn.portal.mn/uploads/67a08987-6c09-4a08-b31b-fb5a41bfe421.jpeg",
  location: {
    name: "Төв Цэнгэлдэх Хүрээлэн",
    description: " ",
  },
  name: '"ПУУЖИН БАЯРАА" Concert',
  startDate: "2024-09-14T10:00:00.000Z",
  _id: "65f04886119dd68c1393ead6",
};

export const CDN_BASE = "https://cdn.portal.mn/";

export const ADMIN_USER = [
  "puntsii",
  "amgaa-test",
  "handa",
  "khebe",
  "boku511",
  "urenchinochir",
  "azaaka",
  "temka1224",
  "amgaa-test",
  "discodisco",
];
