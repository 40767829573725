/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Otis Admin PRO React components
import MDTypography from "components/MDTypography";

function PlainTextCell({ value }) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value?.replace(/<[^>]+>/g, "")}
    </MDTypography>
  );
}

// Typechecking props for the PlainTextCell
PlainTextCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PlainTextCell;
