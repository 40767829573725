import React, { useState } from "react";
import MDButton from "components/MDButton";
import MyModal from "components/Modal";
import FormItem from "components/FormItem";

import Form from "rc-field-form";
import { NotificationManager } from "react-notifications";
import NFTTemplateModal from "./NFTTemplateModal";
import NumberInput from "../../../components/NumberInput";
import MDBox from "../../../components/MDBox";
import { useMutation } from "../../../lib/hooks";
import { useAuth } from "../../../context/auth-provider";
import { ADMIN_USER } from "../../../constant";

function NFTTemplate({ id, ticketTemplateId, refetch }) {
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const [openNFT, setOpenNFT] = useState(false);
  const { request, loading } = useMutation({ uri: `/mongolnft/nft/${ticketTemplateId}` });

  if (!ADMIN_USER.includes(user.username)) return null;
  return (
    <div>
      {id ? (
        <MDButton variant="gradient" color="success" size="large" onClick={() => setOpenNFT(true)}>
          Generate NFT
        </MDButton>
      ) : (
        <MDButton variant="gradient" color="success" size="large" onClick={() => setOpen(true)}>
          Create NFT Template
        </MDButton>
      )}
      <NFTTemplateModal
        open={open}
        refetch={refetch}
        ticketTemplateId={ticketTemplateId}
        onClose={() => setOpen(false)}
      />
      <MyModal open={openNFT} onClose={() => setOpenNFT(false)} title="Generate NFTs">
        <Form
          initialValues={{ count: 0 }}
          onFinish={(values) =>
            request(values).then((res) => {
              if (res?.success) {
                setOpenNFT(false);
                return NotificationManager.success(`${res.length} NFT created`);
              }
              NotificationManager.warning(`Something went worng`);
            })
          }
        >
          <FormItem name="count" rules={[{ required: true }]}>
            <NumberInput label="Count" placeholder="1200" />
          </FormItem>
          <MDBox mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton
              color="secondary"
              variant="gradient"
              disabled={loading}
              onClick={() => setOpenNFT(false)}
            >
              Cancel
            </MDButton>
            <MDButton type="submit" variant="gradient" color="info" loading={loading}>
              Generate
            </MDButton>
          </MDBox>
        </Form>
      </MyModal>
    </div>
  );
}

export default NFTTemplate;
