import React from "react";
import Card from "@mui/material/Card";
import { NotificationManager } from "react-notifications";
import MyModal from "../../components/Modal";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { useMutation } from "../../lib/hooks";
import MDAvatar from "../../components/MDAvatar";
import RenderOption from "../merchant/merch-templates/[id]/RenderOption";
import { getKeyFromName } from "../../lib/utils/merchandise";
import DefaultCell from "../../examples/Tables/DefaultCell";
import Status from "./Status";

function PreviewModal({ open, onClose, active, refetch }) {
  const [image] = active?.productId?.templateId?.imageUrl || [];
  const { request, loading } = useMutation({ uri: `/delivery/${active?._id}`, method: "patch" });

  const handleStatus = (status) => () => {
    request({ status }, `/delivery/${active?._id}`)
      .then((res) => {
        if (res._id) {
          refetch();
          onClose();
          NotificationManager.success("Амжилттай");
        }
      })
      .catch((err) => NotificationManager.warning(err?.message || err));
  };

  return (
    <MyModal open={open} onClose={onClose} title="Delivery preview">
      <div>
        <MDBox display="flex" flexWrap="wrap" gap={1} alignItems="flex-start" mb={3}>
          <MDAvatar size="xxl" src={image} variant="rounded" alt="portal" />
          <div>
            <DefaultCell value={active?.productId?.name} />
            <MDBox display="flex" flexWrap="wrap" gap={1} alignItems="center" my={1}>
              {Object.entries(active?.productId?.merchItemId?.attrs || {})?.map(([key, val]) => (
                <RenderOption value={val} isColor={getKeyFromName(key) === "color"} />
              ))}
            </MDBox>
            <Status status={active?.status} />
          </div>
        </MDBox>
        <Card sx={{ marginBottom: 2 }}>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Receiver
            </MDTypography>
          </MDBox>
          <MDBox p={2} pt={0} pl={4} display="flex" flexDirection="column">
            <MDTypography variant="button">{active?.receiverName}</MDTypography>
            <MDTypography variant="button">{active?.receiverEmail}</MDTypography>
            <MDTypography variant="button">{active?.phoneNumber}</MDTypography>
          </MDBox>
        </Card>
        <Card sx={{ marginBottom: 2 }}>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Address
            </MDTypography>
          </MDBox>
          <MDBox p={2} pt={0} pl={4} display="flex" flexDirection="column">
            <MDTypography variant="button">{active?.city}</MDTypography>
            <MDTypography variant="button">{active?.district}</MDTypography>
            <MDTypography variant="button">{active?.quarter}</MDTypography>

            <MDTypography variant="button">{active?.extendedAddress}</MDTypography>
          </MDBox>
        </Card>
        <Card sx={{ marginBottom: 4 }}>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Additional info
            </MDTypography>
          </MDBox>
          <MDBox p={2} pt={0} pl={4} display="flex" flexDirection="column">
            <MDTypography variant="button">{active?.additionalInfo}</MDTypography>
          </MDBox>
        </Card>
        <MDBox display="flex" gap={2}>
          <MDButton
            color="warning"
            loading={loading}
            onClick={handleStatus("PROCESSING")}
            disabled={active?.status === "PROCESSING"}
          >
            Мэдээлэл бүртгэгдсэн
          </MDButton>
          <MDButton
            color="info"
            loading={loading}
            onClick={handleStatus("SHIPPED")}
            disabled={active?.status === "SHIPPED"}
          >
            Хүргэлт эхэлсэн
          </MDButton>
          <MDButton
            color="success"
            loading={loading}
            onClick={handleStatus("DELIVERED")}
            disabled={active?.status === "DELIVERED"}
          >
            Хүргэгдсэн
          </MDButton>
          <MDButton
            color="error"
            loading={loading}
            onClick={handleStatus("RETURNED")}
            disabled={active?.status === "RETURNED"}
          >
            Буцаагдсан
          </MDButton>
        </MDBox>
      </div>
    </MyModal>
  );
}

export default PreviewModal;
