import React from "react";
import MDTypography from "../../components/MDTypography";

// "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//     "text",
//     "white"

const colorMap = {
  PROCESSING: "warning",
  SHIPPED: "info",
  DELIVERED: "success",
  RETURNED: "error",
};

const statusMap = {
  PROCESSING: "Мэдээлэл бүртгэгдсэн",
  SHIPPED: "Хүргэлт эхэлсэн",
  DELIVERED: "Хүргэгдсэн",
  RETURNED: "Буцаагдсан",
};

function Status({ status }) {
  return (
    <MDTypography variant="subtitle2" fontWeight="medium" color={colorMap[status] || "secondary"}>
      {statusMap[status] || statusMap.PROCESSING}
    </MDTypography>
  );
}

export default Status;
