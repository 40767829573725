import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "rc-field-form";
import { useMutation, useQuery } from "lib/hooks";
import { NotificationManager } from "react-notifications";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormItem from "components/FormItem";
import MDDatePicker from "components/MDDatePicker";
import Loader from "components/Loader";
import MDAvatar from "components/MDAvatar";
import { formatMoney } from "lib/utils/helper";
import EventAutocomplete from "../../ticket-templates/EventAutocomplete";
import AddOrEditModal from "./AddOrEditModal";
import DeleteModal from "../../merchant/merch-templates/[id]/DeleteModal";

function LoungeDetail() {
  const [active, setActive] = useState(null);
  const [remove, setRemove] = useState(null);
  const { id } = useParams();
  const isNew = id === "new";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    data,
    loading: fetching,
    fetchData,
  } = useQuery({ uri: `/ticket-bar/${id}`, manual: isNew });

  const { request, loading: saving } = useMutation({
    uri: `/ticket-bar/${id}`,
    method: isNew ? "post" : `put`,
  });

  useEffect(() => {
    if (data?._id) {
      form.setFieldsValue(data);
    }
  }, [fetching]);

  return (
    <>
      {fetching ? (
        <Loader />
      ) : (
        <Form
          form={form}
          initialValues={data}
          onFinish={({ eventId, ...rest }) => {
            request(
              {
                ...rest,
                merchants: ["portal"],
              },
              `/ticket-bar/${isNew ? eventId?._id : id}`
            )
              .then((res) => {
                NotificationManager.success(res?.message || "Saved");
                if (res?._id) navigate(`/lounges/${res?._id}`);
              })
              .catch((er) => NotificationManager.success(er?.message || er));
          }}
        >
          <div>
            <AddOrEditModal
              id={id}
              item={active}
              open={!!active}
              refetch={() => fetchData(`/ticket-bar/${id}`)}
              onClose={() => setActive(null)}
            />
            <DeleteModal
              open={!!remove}
              onClose={() => setRemove(null)}
              uri={`/ticket-bar/${id}/bar-item/${remove?.id}`}
              onSuccess={() => {
                setRemove(null);
                fetchData();
              }}
            />
            <MDBox p={0}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name={["eventId"]} rules={[{ required: true }]}>
                    <EventAutocomplete disabled={!!data?._id} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name="openDate" rules={[{ required: true }]}>
                    <MDDatePicker input={{ label: "Open date", name: "openDate" }} />
                  </FormItem>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <FormItem name="closeDate" rules={[{ required: true }]}>
                    <MDDatePicker input={{ label: "Close date", name: "closeDate" }} />
                  </FormItem>
                </Grid>
              </Grid>
              {!isNew && (
                <>
                  <MDTypography variant="h5" mb={2}>
                    Items
                  </MDTypography>
                  <Grid container spacing={3} mb={3}>
                    {data?.items?.map((item) => (
                      <Grid item xs={12} md={6} xl={4} key={item.id}>
                        <Card>
                          <MDBox p={2}>
                            <MDBox display="flex" alignItems="center" gap={1}>
                              <MDAvatar size="xl" src={item.image} variant="rounded" />
                              <MDBox display="flex" flexDirection="column" flex="1">
                                <MDTypography variant="h6">{item.name}</MDTypography>
                                <MDTypography variant="caption">
                                  {item.name_en} - {item?.type}
                                </MDTypography>
                                <MDTypography variant="caption">
                                  {formatMoney(item.sellPrice.amount)}
                                </MDTypography>
                                <MDTypography
                                  variant="caption"
                                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                >
                                  Total:{" "}
                                  <MDTypography variant="caption" fontWeight="bold">
                                    {item.qty}
                                  </MDTypography>
                                  Available:{" "}
                                  <MDTypography variant="caption" fontWeight="bold">
                                    {item.avail}
                                  </MDTypography>
                                </MDTypography>
                              </MDBox>
                              <MDBox display="flex" flexDirection="column" gap={1}>
                                <MDButton
                                  iconOnly
                                  color="info"
                                  variant="contained"
                                  onClick={() => setActive(item)}
                                >
                                  <Icon>edit</Icon>
                                </MDButton>
                                <MDButton
                                  iconOnly
                                  color="warning"
                                  variant="contained"
                                  onClick={() => setRemove(item)}
                                >
                                  <Icon>delete</Icon>
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <MDButton
                    mb={2}
                    color="info"
                    size="large"
                    sx={{ marginBottom: 2 }}
                    variant="gradient"
                    onClick={() => setActive({})}
                  >
                    CREATE ITEM
                  </MDButton>
                </>
              )}
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  size="large"
                  disabled={saving}
                  loading={saving}
                >
                  Save
                </MDButton>
              </MDBox>
            </MDBox>
          </div>
        </Form>
      )}
    </>
  );
}

export default LoungeDetail;
